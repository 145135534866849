<template>
    <div class="p-5">
        <div class="mb-5 text-center border rounded-sm p-2.5 inline-block bg-white" style="min-width: 500px">
            <div class="text-3xl font-semibold">{{serial}}</div>
            <div class="text-gray-400">SERIAL NUMBER</div>
        </div>
        <div class="mb-5">
            <div>Product Name</div>
            <div class="text-lg font-semibold">{{name}}</div>
            <div class="text-sm text-gray-400">SKU: {{sku}}</div>
        </div>
        <div class="grid grid-cols-2 gap-5">
            <div class="col-span-1">
                <legend class="border-b mb-2 font-medium text-green-500">Document In</legend>
                <div class="border rounded-sm p-2.5">
                    <div>Pruchase Order</div>
                    <div class="text-lg font-semibold">1709/DPI/PO/22/8</div>
                    <div>17 Sep 2022, 17:46 (John Doe)</div>
                    <div class="mt-5">
                        Supplier
                    </div>
                </div>
            </div>
            <div class="col-span-1">
                <legend class="border-b mb-2 font-medium text-red-500">Document Out</legend>
                <div class="border rounded-sm p-2.5">
                    <div>Invoices</div>
                    <div v-for="(inv ,i) in invoices" :key="i" class="mb-5 border-b pb-2">
                        <div class="text-lg font-semibold">{{inv.invoiceNo}}</div>
                        <div class="text-sm">{{getDate(inv.createdAt)}}</div>
                        <div class="mt-2">
                            <div>Customer</div>
                            <div>
                                <div class="font-semibold">{{inv.customer.name}}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { moment } from '../../moment'
import BreadcrumbManager from '../../mixins/breadcrumbsManager.vue'
export default {
    mixins: [BreadcrumbManager],
    data () {
        return {
            serial: '',
            name: '',
            sku: '',
            invoices: '',
        }
    },
    mounted () {
        
        var serialId = this.$route.params.id 
        this.getData(serialId)
    },
    methods: {
        getData (id) {
            axios.get('/serial/detail/'+id)
            .then(res => {
                this.serial = res.data.serialNumber 
                this.name = res.data.name
                this.sku = res.data.sku
                this.invoices = res.data.invoices
                this.pushBreadcrumb({
                    text: this.serial
                })
            })
        },
        getDate (d) {
            return moment(d)
        }
    },
}
</script>